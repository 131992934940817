@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");
.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: white;

}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.overlay {
  opacity: 0.9;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-image: url('./assets/background/background.gif');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

@media screen and (max-width: 600px) {
  .text {
    font-size: 8px!important;
  }
  .logo {
    margin-left: -100px;
  }
  .onlyDesktop {
    visibility: hidden;
    display: none;
  }
  .marginOnPhone {
    margin-right: 100px;
  }
}